export default [
  // DRC
  {
    country: "Democratic Republic of Congo",
    regions: [
      {
        province: "Province de l'Ituri",
        towns: ["Bunia"],
        territories: ["Djugu", "Irumu", "Mambasa", "Aru", "Mahagi"],
      },
      {
        province: "Province de la Tshopo",
        towns: ["Kisangani"],
        territories: [
          "Banalia",
          "Bafwasende",
          "Ubundu",
          "Opala",
          "Yahuma",
          "Isangi",
        ],
      },
      {
        province: "Province Nord Kivu",
        towns: ["Goma"],
        territories: ["Rutshuru", "Masisi", "Beni", "Lubero"],
      },
      {
        province: "Province Sud Kivu",
        towns: ["Bukavu"],
        territories: [
          "Fizi",
          "Idjwi",
          "Kabare",
          "Kalehe",
          "Mwenga",
          "Shabunda",
        ],
      },
      {
        province: "Province du Maniema",
        towns: ["Kindu"],
        territories: [
          "Kabambare",
          "Kailo",
          "Kasongo",
          "Kibombo",
          "Lubutu",
          "Pangi",
          "Punia",
        ],
      },
      {
        province: "Province du Tanganyika",
        towns: ["Kalemie"],
        territories: ["Kongolo", "Manono", "Moba", "Njunzu"],
      },
      {
        province: "Province du Haut Lomami",
        towns: ["Kamina"],
        territories: ["Kabongo", "Malemba-nkulu", "Kaniama", "Bukama"],
      },
      {
        province: "Province du Haut Katanga ",
        towns: ["Lubumbashi"],
        territories: [
          "Pweto",
          "Kipushi",
          "Nitwaba",
          "Kasenga",
          "Sakaria",
          "Kambeve",
        ],
      },
      {
        province: "Province de Lualua",
        towns: ["Kolwezi"],
        territories: ["Kapanga", "Sandoa", "Dilolo", "Lubudi", "Mutshatsha"],
      },
      {
        province: "Province  de Lomami ",
        towns: ["Kabinda"],
        territories: ["Kamiji", "Lubao", "Ngandajika", "Luilu"],
      },
      {
        province: "Province de Lulua",
        towns: ["Demba"],
        territories: ["Mwanza ngoma", "Lulua"],
      },
      {
        province: "Province du Kasaï oriental",
        towns: ["Mbuji-Mayi"],
        territories: [
          "Kabeya-Kamuanga",
          "Lupatapata",
          "Tshilenge",
          "Katandamiabi",
        ],
      },
      {
        province: "Province du Kasaï",
        towns: ["Kananga"],
        territories: [
          "Tshimbulu",
          "Demba",
          "Dibaya",
          "Dimbelenge",
          "Kazumbaluiza",
        ],
      },
      {
        province: "Province de Sankuru",
        towns: ["Lusambo"],
        territories: [
          "Katako-kombe",
          "Kole",
          "Lodja",
          "Lusambo",
          "Lubefu",
          "Lomela",
        ],
      },
      {
        province: "Province de Tshuapa",
        towns: ["Boende"],
        territories: ["Monkoto", "De beface", "Dikela", "Djolu", "Bokungu"],
      },
      {
        province: "Province de Bas Uele",
        towns: ["Buta"],
        territories: ["Oketi", "Bambesa", "Ango"],
      },
      {
        province: "Province de Haut Uele",
        towns: ["Isiro"],
        territories: [
          "Dungu",
          "Rungu",
          "Wamba",
          "Watsa",
          "Niangara",
          "Faradje",
        ],
      },
      {
        province: "Province de Norb Ubangi",
        towns: ["Gbadolite"],
        territories: ["Mobayi-Mbogo", "Bosobolo", "Yakoma", "Businga"],
      },
      {
        province: "Province du Sud Ubangi",
        towns: ["Gemena (Kikwit)"],
        territories: ["Kungu", "Libenge", "Budjala"],
      },
      {
        province: "province de Mongala",
        towns: ["Lisala"],
        territories: ["Bumba", "Bangandanga"],
      },
      {
        province: "Province de l'Equateur ",
        towns: ["Mbandaka "],
        territories: [
          "Bomongo",
          "Basankusu",
          "Ingende",
          "Bikoro",
          "Lukolela",
          "Bikoro makanza",
        ],
      },
      {
        province: "Province de Maï-Ndombe",
        towns: ["Inongo"],
        territories: [
          "Kiri",
          "Oshwe",
          "Bolobo",
          "Yumbi",
          "Mushi",
          "Lokolelo",
          "Kwamouth",
        ],
      },
      {
        province: "Province de Kwilu",
        towns: ["Bandundu"],
        territories: ["Bulungu, Idiofa, Gungu, Masimanimba, Bagata, Kazamba"],
      },
      {
        province: "Province de Kongo Central",
        towns: ["Boma & Matadi"],
        territories: [
          "Lukula-seke-banzo, Tshua, Luozi, Muanda, Songololo, Kasangulu, Kimvula, Mbanza-ngungu",
        ],
      },
      {
        province: "Province de Kwango",
        towns: ["Kenge"],
        territories: ["Feshi, Kahemba, Kasongo-lunda, Popokabaka"],
      },
      {
        province: "Province de Kinshasa",
        towns: ["Kinshasa"],
        territories: [
          "Bandalungwa, Barumbu, Bumbu, Gombe, Kalamu, Kasa-vubu, Kimbaseke, Kinshasa,",
        ],
      },
    ],
  },

  // TANZANIA
  {
    country: "Tanzania",
    regions: [
      {
        province: "Arusha",
        towns: ["Arusha"],
        territories: [
          "Arusha City",
          "Arusha Rural",
          "Karatu",
          "Longido",
          "Meru",
          "Monduli",
          "Ngorongoro",
        ],
      },
      {
        province: "DAR-ES-SALAAM",
        towns: ["DAR-ES-SALAAM"],
        territories: ["Kinondoni", "Ilala", "Temeke", "Ubungo", "Kigamboni"],
      },
      {
        province: "DODOMA",
        towns: ["DODOMA"],
        territories: [
          "Bahi",
          "Chamwino",
          "Chemba",
          "Dodoma",
          "Kondoa",
          "Kongwa",
          "Mpwapwa",
        ],
      },
      {
        province: "GEITA",
        towns: ["GEITA"],
        territories: ["Bukombe", "Chato", "Geita", "Mbogwe", "Nyang'hwale"],
      },
      {
        province: "IRINGA",
        towns: ["IRINGA"],
        territories: [
          "Iringa",
          "Kilolo",
          "Mufindi",
          "Iringa Municipal Council",
          "Mafinga Town Council",
        ],
      },
      {
        province: "KAGERA",
        towns: ["BUKOBA"],
        territories: [
          "Biharamulo",
          "Bukoba Rural",
          "Bukoba Urban",
          "Karagwe",
          "Kyerwa",
          "Missenyi",
          "Muleba",
          "Ngara",
        ],
      },
      {
        province: "KATAVI",
        towns: ["MPANDA"],
        territories: ["Nsimbo", "Mpanda", "Mlele"],
      },
      {
        province: "KIGOMA",
        towns: ["KIGOMA"],
        territories: [
          "Buhigwe",
          "Kakonko",
          "Kasulu Rural",
          "Kasulu Urban",
          "Kibondo",
          "Kigoma Rural",
          "Kigoma Urban",
          "Uvinza",
        ],
      },
      {
        province: "KILIMANJARO",
        towns: ["MOSHI"],
        territories: [
          "Moshi Municipal Council",
          "Moshi District Council",
          "Hai District Council",
          "Siha District Council",
          "Rombo District Council",
          "Mwanga District Council",
          "Same District Council",
        ],
      },
      {
        province: "LINDI",
        towns: ["LINDI"],
        territories: ["Kilwa", "Lindi", "Liwale", "Nachingwea", "Ruangwa"],
      },
      {
        province: "MANYARA",
        towns: ["BABATI"],
        territories: [
          "Babati Rural",
          "Babati Urban",
          "Hanang",
          "Kiteto",
          "Mbulu",
          "Simanjiro",
        ],
      },
      {
        province: "MARA",
        towns: ["MUSOMA"],
        territories: [
          "Bunda",
          "Butiama",
          "Musoma Rural",
          "Musoma Urban",
          "Rorya",
          "Serengeti",
          "Tarime",
        ],
      },
      {
        province: "MBEYA",
        towns: ["MBEYA"],
        territories: [
          "Chunya",
          "Ileje",
          "Kyela",
          "Mbarali",
          "Mbeya",
          "Mbozi",
          "Momba",
          "Rungwe",
        ],
      },
      {
        province: "ZANZIBAR",
        towns: ["ZANZIBAR CITY"],
        territories: ["East Zanzibar", "West Zanzibar"],
      },
      {
        province: "MOROGORO",
        towns: ["MOROGORO"],
        territories: [
          "Gairo",
          "Kilombero",
          "Kilosa",
          "Morogoro",
          "Mvomero",
          "Ulanga",
        ],
      },
      {
        province: "MTWARA",
        towns: ["MTWARA"],
        territories: ["Masasi", "Mtwara", "Nanyumbu", "Newala", "Tandahimba"],
      },
      {
        province: "MWANZA",
        towns: ["MWANZA"],
        territories: [
          "Ilemela",
          "Kwimba",
          "Magu",
          "Misungwi",
          "Nyamagana",
          "Sengerema",
          "Ukerewe",
        ],
      },
      {
        province: "NJOMBE",
        towns: ["NJOMBE"],
        territories: [
          "Ludewa District",
          "Makambako Town Council",
          "Makete District",
          "Njombe District Council",
          "Njombe Town Council",
          "Wanging'ombe",
        ],
      },
      {
        province: "PEMBA (NORTH)",
        towns: ["WETE"],
        territories: ["Micheweni", "Wete"],
      },
      {
        province: "PEMBA (SOUTH)",
        towns: ["CHAKE-CHAKE"],
        territories: ["Chake Chake", "Mkoani"],
      },
      {
        province: "PWANI",
        towns: ["KIBAHA"],
        territories: [
          "Bagamoyo",
          "Kibaha",
          "Kisarawe",
          "Mafia",
          "Mkuranga",
          "Rufiji",
        ],
      },
      {
        province: "RUKWA",
        towns: ["SUMBAWANGA"],
        territories: ["Kalambo", "Lyamba", "Nkasi", "Sumbawanga"],
      },
      {
        province: "RUVUMA",
        towns: ["SONGEA"],
        territories: ["Mbinga", "Namtumbo", "Nyasa", "Songea", "Tunduru"],
      },
      {
        province: "SHINYANGA",
        towns: ["SHINYANGA"],
        territories: [
          "Kahama Rural",
          "Kahama Urban",
          "Kishapu",
          "Shinyanga Rural",
          "Shinyanga Urban",
        ],
      },
      {
        province: "SIMIYU",
        towns: ["BARIADI"],
        territories: ["Bariadi", "Busega", "Itilima", "Maswa", "Meatu"],
      },
      {
        province: "SINGIDA",
        towns: ["SINGIDA"],
        territories: [
          "Iramba",
          "Ikungi",
          "Manyoni",
          "Mkalama",
          "Singida District",
          "Singida Municipality",
        ],
      },
      {
        province: "SONGWE",
        towns: ["VWAWA"],
        territories: ["Ileje", "Mbozi", "Momba", "Songwe"],
      },
      {
        province: "TABORA",
        towns: ["TABORA"],
        territories: [
          "Igunga",
          "Kaliua",
          "Nzega",
          "Sikonge",
          "Tabora Municipal",
          "Urambo",
          "Uyui",
        ],
      },
      {
        province: "TANGA",
        towns: ["TANGA"],
        territories: [
          "Handeni Rural",
          "Handeni Urban",
          "Kilindi",
          "Korogwe Rural",
          "Korogwe Urban",
          "Lushoto",
          "Muheza",
          "Mkinga",
          "Pangani",
          "Tanga",
        ],
      },
      {
        province: "UNGUJA (NORTH)",
        towns: ["KOKOTONI"],
        territories: ["Kaskazini A", "Kaskazini B"],
      },
      {
        province: "UNGUJA (SOUTH)",
        towns: ["KOANI"],
        territories: ["Kati District", "Kusini District"],
      },
    ],
  },

  {
    // UGANDA
    country: "Uganda",
    regions: [
      {
        province: "Buikwe",
        towns: ["Buikwe"],
        territories: [
          "Kawolo",
          "Buikwe towns council",
          "Lugazi towns council",
          "Jajja",
          "Najembe",
          "Ngogwe",
          "Njeru towns council",
          "Nkokonjeru",
          "Nyenga",
          "Ssi bukunja",
          "Wakisi",
        ],
      },
      {
        province: "Bukomansibi",
        towns: ["Bukomansibi"],
        territories: ["Bigasa", "Butenga", "Kibinge", "Kitanda"],
      },

      {
        province: "Butambala",
        towns: ["Butambala"],
        territories: ["Budde", "Bulo", "Gombe", "Kalamba", "Kibibi", "Ngando"],
      },

      {
        province: "Buvuma",
        towns: ["Buvuma"],
        territories: [
          "Bugaya",
          "Busamuzi",
          "Buwooya",
          "Bweema",
          "Lubya",
          "Lwajje",
          "Lyabaana",
          "Nairambi",
        ],
      },

      {
        province: "Gomba",
        towns: ["Gomba"],
        territories: ["Kabulasoke", "Kanoni", "Kyegonza", "Maddu", "Mpenja"],
      },

      {
        province: "Kalangala",
        towns: ["Bujumba", "Kyamuswa"],
        territories: ["Mugoye", "Bubeke", "Bufumira", "Kyamuswa", "Mazinga"],
      },

      {
        province: "Kalungu",
        towns: ["Kalungu"],
        territories: [
          "Bukulula",
          "Kalungu",
          "Kyamulibwa",
          "Lukaya",
          "Lwabenge",
        ],
      },

      {
        province: "Kampala",
        towns: "Kampala capital city",
        territories: [
          "Kampala central division",
          "Kampala kawempe division",
          "Kampala lubaga division",
          "Kampala makindye division",
          "Kampala nakawa division",
        ],
      },

      {
        province: "Kayunga",
        towns: ["Bbaale", "Ntenjeru"],
        territories: [
          "Bbaale",
          "Galiraaya",
          "Kayonza",
          "Kitimbwa",
          "Busana",
          "Kangulumila",
          "Nazigo",
        ],
      },

      {
        province: "Kyankwanzi",
        towns: ["Kiboga"],
        territories: [
          "Bananywa",
          "Butemba",
          "Gayaza",
          "Kyankwazi",
          "Mulagi",
          "Nkandwa",
          "Nsambya",
          "Ntwetwe",
          "Wattuba",
        ],
      },

      {
        province: "Luwero",
        towns: ["Bamunanika", "Katikamu"],
        territories: [
          "Kalagala",
          "Kamira",
          "Kikyusa",
          "Zirobwe",
          "Bombo",
          "Butuntumula",
          "Katikamu",
          "Makulubita",
          "Nyimbwa",
          "Wobulenzi",
        ],
      },

      {
        province: "Lwengo",
        towns: ["Bukoto"],
        territories: ["Kisekka", "Kkingo", "Kyazanga", "Malongo", "Ndagwe"],
      },

      {
        province: "Lyantonde",
        towns: "Kabula",
        territories: [
          "Kaliiro",
          "Kasagama",
          "Kinuuka",
          "Mpumudde",
          "Lyantonde town council",
        ],
      },

      {
        province: "Masaka",
        towns: ["Bukoto", "Masaka MC"],
        territories: [
          "Bukakata",
          "Buwunga",
          "Kabonera",
          "Kyannamukaaka",
          "Kyesiiga",
          "Mukungwe",
          "Butengo",
          "Kimaanya/kyabakuza",
          "Nyendo",
        ],
      },

      {
        province: "Mityana",
        towns: ["Busujju", "Mityana"],
        territories: [
          "Banda",
          "Butayunja",
          "Kakindu",
          "Maanyi",
          "Malangala",
          "Bulera",
          "Busimbi",
          "Kalangalo",
          "Kikandwa",
          "Mityana town council",
          "Namungo",
          "Sekanyonyi",
        ],
      },

      {
        province: "Mpigi",
        towns: ["Mawokota"],
        territories: [
          "Buwama",
          "Kamengo",
          "Kiringente",
          "Kituntu",
          "Muduuma",
          "Nkozi",
        ],
      },

      {
        province: "Mubende",
        towns: ["Buwekula", "Kassanda", "Kasambya"],
        territories: [
          "Butoloogo",
          "Kitenga",
          "Kiyuni",
          "Madudu",
          "Bagezza",
          "Mubende town council",
          "Kasambya",
          "Nabingoola",
          "Bukuya",
          "Kassanda",
          "Makokoto",
          "Myanzi",
          "Nalutuntu",
          "Kiganda",
        ],
      },
      {
        province: "Mukono",
        towns: ["Mukono", "Mukono MC", "Nakifuma"],
        territories: [
          "Koome",
          "Kyampisi",
          "Mpatta",
          "Mpunge",
          "Nakisunga",
          "Nama",
          "Ntenjeru",
          "Goma",
          "Kasawo",
          "Ntunda",
          "Seeta-namuganga",
          "Nagojje",
          "Kimenyedde",
          "Nabbaale",
        ],
      },
      {
        province: "Nakaseke",
        towns: ["Nakaseke"],
        territories: [
          "Butalangu town council",
          "Kapeeka",
          "Kasangombe",
          "Kikamulo",
          "Kinoni",
          "Kinyogoga",
          "Kito",
          "Kiwoko",
          "Ngoma",
          "Semuto",
          "Wakyato",
        ],
      },
      {
        province: "Nakasongola",
        towns: ["Buruli"],
        territories: [
          "Kakooge",
          "Kalongo",
          "Kalungi",
          "Lwabiyata",
          "Lwampanga",
          "Migeera",
          "Nabisweera",
          "Nakitoma",
          "Wabinyonyi",
        ],
      },

      {
        province: "Rakai",
        towns: ["Kakuuto", "Kooki", "Kyotera"],
        territories: [
          "Kasasa",
          "Kibanda",
          "Kifamba",
          "Kyebe",
          "Byakabanda",
          "Ddwaniro",
          "Kacheera",
          "Kagamba",
          "Kiziba",
        ],
      },
      {
        province: "Ssembabule",
        towns: ["Lwemiyaga", "Mawogola"],
        territories: [
          "Ntusi",
          "Lugusulu",
          "webitakuli",
          "Mateete",
          "Mijwala",
          "Ssembabule town council",
        ],
      },
      {
        province: "Wakiso",
        towns: ["Busiro", "Entebbe MC", "Kyadondo"],
        territories: [
          "Bussi",
          "Kakiri",
          "Kasanje",
          "Katabi",
          "Masulita",
          "Mende",
          "Namayuba",
          "Nsangi",
          "Ssisa",
          "Wakiso town council",
          "Division A",
          "Division B",
          "Busukuma",
          "Gombe",
          "Kira town council",
          "Makindye ssabagabo",
          "Nabweru",
          "Nangabo",
          "Nansana town council",
        ],
      },
      {
        province: "Amuria",
        towns: ["Amuria", "Kapelebyong"],
        territories: [
          "Abarilela",
          "Akeriau",
          "Apeduru",
          "Asamuk",
          "Kuju",
          "Morungatuny",
          "Ogolai",
          "Orungo",
          "Wera",
        ],
      },
      {
        province: "Budaka",
        towns: ["Budaka", "Iki iki"],
        territories: [
          "Kachomo",
          "Kaderuna",
          "Kakule",
          "Lyama",
          "Naboa",
          "Nansanga",
          "Kameruka",
          "Mugiti",
          "Katira",
        ],
      },
      {
        province: "Bududa",
        towns: ["Manjiya"],
        territories: [
          "Bubiita",
          "Bukalasi",
          "Bukibokolo",
          "Bukigai",
          "Bulukecheke",
          "Bumayoka",
          "Bushika",
          "Buwali",
          "Nakatsi",
          "Nalwanza",
        ],
      },
      {
        province: "Bugiri",
        towns: ["Bukooli"],
        territories: [
          "Budhaya",
          "Bulesa",
          "Bulidha",
          "Buluguyi",
          "Buwunga",
          "Iwemba",
          "Nabukalu",
          "Nankoma",
        ],
      },
      {
        province: "Bukedea",
        towns: ["Bukedea"],
        territories: ["Kachumbali", "Kidongole", "Kolir", "Malera"],
      },
      {
        province: "Bukwo",
        towns: ["Kongasis"],
        territories: [
          "Chepkwasta",
          "Chesower",
          "Kabei",
          "Kamet",
          "Kortek",
          "Riwo",
          "Senendet",
          "Suam",
        ],
      },
      {
        province: "Bulambuli",
        towns: ["Bulambuli"],
        territories: [
          "Buginyanya",
          "Bukhali",
          "Bulaago",
          "Bulegeni",
          "Kamu",
          "Lusha",
        ],
      },
      {
        province: "Busia",
        towns: ["Busia MC", "Samia-bugwe"],
        territories: [
          "Buhehe",
          "Bulumbi",
          "Busime",
          "Busitema",
          "Buteba",
          "Buyanga",
        ],
      },
      {
        province: "Butaleja",
        towns: ["Bunyole"],
        territories: [
          "Budumba",
          "Busaba",
          "Busolwe",
          "Butaleja",
          "Himutu",
          "Kachonga",
          "Naweyo",
        ],
      },
      {
        province: "Buyende",
        towns: ["Budiope"],
        territories: ["Bugaya", "Kagulu", "Kidera", "Nkondo"],
      },
      {
        province: "Iganga",
        towns: ["Bugweri", "Iganga MC", "Kigulu"],
        territories: [
          "Busembatia",
          "Buyanga",
          "Ibulanku",
          "Igombe",
          "Makuutu",
          "Namalemba",
          "Bulamogi",
          "Nabitende",
          "Nakalama",
          "Nakigo",
        ],
      },
      {
        province: "Jinja",
        towns: ["Butembe", "Jinja MC", "Kagoma"],
        territories: [
          "Bugembe",
          "Busedde",
          "Kakira",
          "Mafumbira",
          "Kimaka",
          "Nalufenya",
          "Masese",
          "Budondo",
          "Butagaya",
          "Buwenge",
          "Buyengo",
        ],
      },

      {
        province: "Kaberamaido",
        towns: ["Kaberamaido", "Kalaki"],
        territories: [
          "Alwa",
          "Aperikira",
          "Kobulubulu",
          "Ochero",
          "Anyara",
          "Apapai",
        ],
      },

      {
        province: "Kaliro",
        towns: ["Bulamogi"],
        territories: ["Mumanya", "Gadumire", "Namugongo", "Namwiwa"],
      },

      {
        province: "Kamuli",
        towns: ["Bugabula", "Buzaaya"],
        territories: [
          "Balawali",
          "Bulopa",
          "Butansi",
          "Kitayunjwa",
          "Nabwigulu",
          "Namasagali",
          "Kisozi",
          "Mbulamuti",
          "Wankole",
        ],
      },

      {
        province: "Kapchorwa",
        towns: ["Tingey"],
        territories: [
          "Amukol",
          "Chema",
          "Chepteret",
          "Gamogo",
          "Kabenywa",
          "Kapchesombe",
          "Kapsinda",
          "Kaptanya",
          "Kaserem",
          "Kawowo",
        ],
      },

      {
        province: "Katakwi",
        towns: ["Toroma", "Usuk"],
        territories: [
          "Kapujan",
          "Magoro",
          "Omodoi",
          "Toroma",
          "Katakwi",
          "Ngariam",
          "Ongongoja",
          "Palam",
          "Usuk",
        ],
      },

      {
        province: "Kibuku",
        towns: ["Kibuku"],
        territories: ["Bulangira", "Buseta", "Kabweri", "Kadama", "Kaguma"],
      },

      {
        province: "Kumi",
        towns: ["Kumi"],
        territories: ["Atutur", "Kanyum", "Mukongoro", "Nyero", "Ongino"],
      },

      {
        province: "Kween",
        towns: ["Kween"],
        territories: [
          "Benet",
          "Binyiny",
          "Kaproron",
          "Kaptoyoy",
          "Kaptum",
          "Kiriki",
          "Moyok",
          "Ngenge",
        ],
      },

      {
        province: "Luuka",
        towns: ["Luuka"],
        territories: ["Bukanga", "Bukooma", "Ikumbya", "Bulongo", "Irongo"],
      },

      {
        province: "Manafwa",
        towns: ["Bubulo"],
        territories: [
          "Bubuto",
          "Bugobero",
          "Bukhabusi",
          "Bukhaweka",
          "Bukhofu",
          "Bukiabi",
          "Bukusu",
          "Bumbo",
        ],
      },

      {
        province: "Mayuge",
        towns: ["Bunya"],
        territories: [
          "Baitambogwe",
          "Bukabooli",
          "Bukatube",
          "Busakira",
          "Buwaaya",
          "Imanyiro",
          "Jaguzi",
          "Kigandalo",
          "Kityerera",
          "Malongo",
          "Mpungwe",
          "Wairasa",
        ],
      },

      {
        province: "Mbale",
        towns: ["Bungokho", "Mbale MC"],
        territories: [
          "Bubyangu",
          "Budwale",
          "Bufumbo",
          "Bukasakya",
          "Bukhende",
          "Bukonde",
          "Bumasikye",
          "Bumbobi",
          "Busano",
          "Busiu",
          "Busoba",
          "Lwasso",
        ],
      },

      {
        province: "Namayingo",
        towns: ["Bukooli"],
        territories: [
          "Banda",
          "Bugana",
          "Buhemba",
          "Buswale",
          "Buyinja",
          "Lolwe",
          "Mutumba",
          "Sigulu island",
        ],
      },

      {
        province: "Namutumba",
        towns: ["Busiki"],
        territories: ["Bulange", "Ivukula", "Kibaale", "Magada", "Nsinze"],
      },

      {
        province: "Ngora",
        towns: ["Ngora"],
        territories: ["Kapir", "Kobwin", "Mukura", "Ngora"],
      },

      {
        province: "Pallisa",
        towns: ["Agule", "Butebo", "Pallisa"],
        territories: [
          "Akisim",
          "Apopong",
          "Chelekura",
          "Gogonya",
          "Kameke",
          "Butebo",
          "Kabwangasi",
          "Kakoro",
          "Kibale",
          "Opwateta",
        ],
      },

      {
        province: "Serere",
        towns: ["Kasilo", "Serere"],
        territories: [
          "Bugondo",
          "Kadungulu",
          "Labori",
          "Pingire",
          "Atiira",
          "Kateta",
          "Kyere",
          "Olio",
        ],
      },

      {
        province: "Sironko",
        towns: ["Budadiri"],
        territories: [
          "Bugitwima",
          "Buhugu",
          "Bukiise",
          "Bukyampo",
          "Bukiiyi",
          "Bukyampi",
          "Bumalimba",
          "Bumasifwa",
          "Bunyafa",
          "Busulani",
          "Butandiga",
          "Buteza",
          "Buwalasi",
          "Bunyobo",
          "Masaba",
        ],
      },

      {
        province: "Soroti",
        towns: ["Soroti", "Soroti MC"],
        territories: ["Arapai", "Asuret", "Gweri", "Kamuda", "Katine", "Tubur"],
      },

      {
        province: "Tororo",
        towns: ["Tororo", "Tororo MC", "West budama"],
        territories: [
          "Kwapa",
          "Mella",
          "Merikit",
          "Molo",
          "Mukujju",
          "Osukuru",
          "Iyolwa",
          "Kirewa",
          "Kisoko",
          "Magola",
          "Mulanda",
        ],
      },

      {
        province: "Abim",
        towns: ["Labwor"],
        territories: ["Alerek", "Lotukei", "Morulem"],
      },

      {
        province: "Adjumani",
        towns: ["East moyo"],
        territories: [
          "Adropi",
          "Arinyapi",
          "Ciforo",
          "Dzaipi",
          "Itirikwa",
          "Ofua",
          "Okusijoni",
          "Pacara",
        ],
      },

      {
        province: "Agago",
        towns: ["Agago"],
        territories: [
          "Adilang",
          "Arum",
          "Kalongo",
          "Kotomol",
          "Lamiyo",
          "Lapono",
          "Lokole",
          "Omiyapacha",
          "Omot",
          "Paimol",
          "Parabong",
          "Patongo",
        ],
      },

      {
        province: "Alebtong",
        towns: ["Moroto"],
        territories: [
          "Abako",
          "Abia",
          "Akura",
          "Alebtong town council",
          "Aloi",
          "Amugu",
          "Apala",
          "Omoro",
        ],
      },

      {
        province: "Amolatar",
        towns: ["Kioga"],
        territories: ["Agikdak", "Agwingir", "Akwon", "Aputi"],
      },

      {
        province: " Amudat",
        towns: ["Pokot", "Kilak"],
        territories: ["Amudat", "Karita", "Loroo", "Amuru", "Atiak", "Lamogi"],
      },

      {
        province: "Apac",
        towns: ["Kwania", "Maruzi"],
        territories: [
          "Adongomola",
          "Aduku",
          "Chawente",
          "Inomo",
          "Nambeiso",
          "Akokoro",
          "Ibuje",
        ],
      },

      {
        province: "Arua",
        towns: ["Arua MC", "Ayivu", "Madi-okollo", "Terego", "Vurra"],
        territories: [
          "River oli",
          "Adumi",
          "Aroi",
          "Ayivuni",
          "Dadamu",
          "Manibe",
          "Oluko",
          "Pajulu",
          "Anyiribu",
          "Offaka",
          "Ogoko",
          "Okollo",
          "Pawor",
          "Rigbo",
          "Katrini",
          "Omugo",
          "Udupi",
        ],
      },

      {
        province: "Dokolo",
        towns: ["Dokolo"],
        territories: [
          "Adeknino",
          "Adok",
          "Agwata",
          "Amwoma",
          "Batta",
          "Dokolo",
          "Kangai",
          "Kwera",
        ],
      },

      {
        province: "Gulu",
        towns: ["Aswa", "Gulu MC", "Omoro"],
        territories: [
          "Awach",
          "Bungatira",
          "Paicho",
          "Palaro",
          "Patiko",
          "Unyama",
          "Bar dege division",
          "Bobi",
          "Koro",
          "Lakwana",
          "Lalogi",
          "Odek",
          "Ongako",
        ],
      },

      {
        province: "Kaabong",
        towns: ["Dodoth"],
        territories: [
          "Kabong east",
          "Kabong west",
          "Kalapata",
          "Kamion",
          "Kadepo",
          "Kathile",
          "Lobalangit",
          "Lodiko",
          "Loyoro",
        ],
      },

      {
        province: "Kitgum",
        towns: ["Chua"],
        territories: [
          "Labongo akwang",
          "Labongo layamo",
          "Labongo amida",
          "Orom",
          "Lagoro",
        ],
      },

      {
        province: "Koboko",
        towns: ["Koboko"],
        territories: ["Abuku", "Dranya", "Kuluba", "Lobule"],
      },

      {
        province: "Kole",
        towns: ["Kole"],
        territories: ["Aboke", "Akalo", "Alito", "Ayer", "Bala"],
      },

      {
        province: "Kotido",
        towns: ["Jie"],
        territories: ["Kachera", "Kotido", "Panyangara", "Rengen"],
      },

      {
        province: "Lamwo",
        towns: ["Lamwo"],
        territories: [
          "Agoro",
          "Lokung",
          "Madi opei",
          "Padibe west",
          "Padibe east",
        ],
      },

      {
        province: "Lira",
        towns: ["Erute", "Lira MC"],
        territories: [
          "Agali",
          "Agweng",
          "Adekokwok",
          "Amach",
          "Barr",
          "Ngetta",
        ],
      },

      {
        province: "  Maracha",
        towns: ["Maracha"],
        territories: [
          "Kijomoro",
          "Nyadri",
          "Oleba",
          "Oluffe",
          "Oluvu",
          "Tara",
          "Yivu",
        ],
      },

      {
        province: "  Moroto",
        towns: ["Mathenko", "Moroto MC"],
        territories: ["Katikekile", "Nanduget", "Rupa", "Tapac"],
      },

      {
        province: "Moyo",
        towns: ["Obongi", "West moyo"],
        territories: [
          "Aliba",
          "Gimara",
          "Itula",
          "Difule",
          "Laropi",
          "Lefori",
          "Metu",
        ],
      },

      {
        province: "Nakapiripirit",
        towns: ["Chekwii", "Pian"],
        territories: [
          "Kakomongole",
          "Loregae",
          "Moruita",
          "Namalu",
          "Lolachat",
          "Nabilatuk",
          "Lorengedwat",
        ],
      },

      {
        province: "Napak",
        towns: ["Bokora"],
        territories: [
          "Iriiri",
          "Lokopo",
          "Lopeei",
          "Lorengecora",
          "Lotome",
          "Matany",
        ],
      },

      {
        province: "Nebbi",
        towns: ["Jonam", "Padyere"],
        territories: [
          "Alwi",
          "Panyango",
          "Panyimur",
          "Wadelai",
          "Akworo",
          "Atego",
          "Erussi",
          "Kucwiny",
        ],
      },

      {
        province: "Nwoya",
        towns: ["Nwoya"],
        territories: ["Alero", "Anaka", "Koch-Goma", "Purongo"],
      },

      {
        province: "Otuke",
        towns: ["Otuke"],
        territories: ["Adwari", "Alango", "Ogwette", "Okwang", "Olilm", "Orum"],
      },

      {
        province: "Oyam",
        towns: ["Oyam"],
        territories: [
          "Aber",
          "Abok",
          "Acaba",
          "Aleka",
          "Iceme",
          "Kamdini",
          "Loro",
          "Minakulu",
          "Myene",
        ],
      },

      {
        province: "Pader",
        towns: ["Aruu"],
        territories: [
          "Acholi bur",
          "Angangura",
          "Atanga",
          "Awere",
          "Laguti",
          "Lapur",
          "Latanya",
        ],
      },

      {
        province: "Yumbe",
        towns: ["Aringa"],
        territories: [
          "Apo",
          "Ariwa",
          "Drajini",
          "Kei",
          "Kerwa",
          "Kochi",
          "Kululu",
          "Ludonga",
        ],
      },

      {
        province: "Zombo",
        towns: ["Okoro"],
        territories: [
          "Abanga",
          "Atyak",
          "Jangokoro",
          "Kango",
          "Nyapea",
          "Paidha",
          "Paidha town council",
          "Warr",
          "Zombo town council",
        ],
      },

      {
        province: "Buhweju",
        towns: ["Buhweju"],
        territories: [
          "Bihanga",
          "Bitsya",
          "Burere",
          "Engaju",
          "Karungu",
          "Nyakishana",
        ],
      },

      {
        province: "Buliisa",
        towns: ["Buliisa"],
        territories: [
          "Biiso",
          "Buliisa town council",
          "Butiaba",
          "Kigwera",
          "Ngwedo",
        ],
      },

      {
        province: "Bundibugyo",
        towns: ["Bughendera", "Bwamba"],
        territories: [
          "Bukonzo",
          "Harugale",
          "Ndugutu",
          "Ngamba",
          "Ntororo",
          "Sindila",
          "Bubandi",
          "Bubukwanga",
          "Busaru",
        ],
      },

      {
        province: "Bushenyi",
        towns: ["Bushenyi-ishaka MC", "Igara"],
        territories: [
          "Ishaka division",
          "Nyakabirizi division",
          "Bitooma",
          "Bumbaire",
          "Ibaare",
          "Kakanju",
          "Kyabugimbi",
        ],
      },

      {
        province: "Hoima",
        towns: ["Bugahya", "Hoima MC"],
        territories: [
          "Buhanika",
          "Buseruka",
          "Kigorobya",
          "Kitoba",
          "Kyabigambire",
          "Bugambe",
          "Buhimba",
        ],
      },

      {
        province: "Ibanda",
        towns: ["Ibanda"],
        territories: [
          "Bisheshe",
          "Ibanda town council",
          "Igorora",
          "Ishongororo",
          "Kashangura",
          "Kijongo",
        ],
      },

      {
        province: "Isingiro",
        towns: ["Isingiro", "Bukanga"],
        territories: [
          "Endiinzi",
          "Kashumba",
          "Mbaare",
          "Nakivale",
          "Ngarama",
          "Rugaaga",
        ],
      },

      {
        province: "Kabale",
        towns: ["Kabale MC", "Ndorwa", "Rubanda", "Rukiga"],
        territories: ["Buhara", "Butanda", "Kaharo"],
      },

      {
        province: "Kabarole",
        towns: ["Bunyangabu", "Burahya", "Fort portal MC"],
        territories: [
          "Buheesi",
          "Kabonero",
          "Kateebwa",
          "Kibiito",
          "Kisomoro",
          "Rwimi",
          "Bukuuku",
          "Busoro",
        ],
      },

      {
        province: "Kamwenge",
        towns: ["Kibale", "Kitagwenda"],
        territories: [
          "Biguli",
          "Bihanga",
          "Busiriba",
          "Bwizi",
          "Kabambiro",
          "Kahunge",
          "Kanara",
          "Kicheche",
          "Mahyoro",
          "Ntara",
        ],
      },

      {
        province: "Kanungu",
        towns: ["Kinkiizi"],
        territories: [
          "Butogota",
          "Kambuga",
          "Kanyatorogo",
          "Katete",
          "Kayonza",
          "Kihiihi",
          "Kinaaba",
          "Kirima",
          "Mpungu",
          "Nyakinoni",
        ],
      },

      {
        province: "Kasese",
        towns: ["Bukonzo", "Busongora", "Kasese MC"],
        territories: ["Bwera", "Ihandiro", "Isango", "Karambi", "Kisinga"],
      },

      {
        province: "Kibaale",
        towns: ["Bugangaizi", "Buyaga", "Buyanja"],
        territories: ["Birembo", "Bwanswa", "Kakindo", "Kakumiro"],
      },

      {
        province: "Kiruhura",
        towns: ["Kazo", "Nyabushozi"],
        territories: [
          "Buremba",
          "Burunga",
          "Engari",
          "Kanoni",
          "Kazo town council",
          "Kiruhura town council",
          "Sanga town council",
        ],
      },

      {
        province: "Kiryandongo",
        towns: ["Kibanda"],
        territories: [
          "Bweyale town council",
          "Kigumba",
          "Kiryandongo",
          "Mutunda",
        ],
      },

      {
        province: "Kisoro",
        towns: ["Bufumbira"],
        territories: [
          "Bukimbiri",
          "Busanza",
          "Chahi",
          "Kanaba",
          "Kirundo",
          "Muramba",
          "Murora",
        ],
      },

      {
        province: "Kyegegwa",
        towns: ["Kyaka"],
        territories: ["Hapuuyo", "Kabweeza", "Kyegegwa", "Ruyonza"],
      },

      {
        province: "Kyenjojo",
        towns: ["Mwenge"],
        territories: ["Bufunjo", "Butunduzi", "Katookw"],
      },

      {
        province: "Masindi",
        towns: ["Mwenge", "Bujenje", "Buruuli", "Masindi MC"],
        territories: [
          "Nyantungo",
          "Budongo",
          "Bwijanga",
          "Kimengo",
          "Miirya",
          "Pakanyi",
          "Karujubu",
          "Kigulya",
        ],
      },

      {
        province: "Mbarara",
        towns: ["Kashari", "Mbarara MC", "Rwampara"],
        territories: [
          "Bubaare",
          "Bukiiro",
          "Kagongi",
          "Kashare",
          "Rubindi",
          "Biharwe",
          "Kakiika",
          "Kakoba",
          "Kamukuzi",
          "Nyakayojo",
          "Nyamitanga",
          "Mwizi",
        ],
      },

      {
        province: "Mitooma",
        towns: ["Ruhinda"],
        territories: ["Bitereko", "Kabira", "Kiyanga"],
      },

      {
        province: "Ntoroko",
        towns: ["Ntoroko"],
        territories: ["Butungama", "Bweramule", "Kanara"],
      },

      {
        province: "Ntungamo",
        towns: ["Kajara", "Ntungamo MC", "Rushenyi", "Ruhaama"],
        territories: [
          "Bwongyera",
          "Ihunga",
          "Kibatsi",
          "Rwashamaire",
          "Itojo",
          "Ntungamo",
          "Kayonza",
          "Rubaare",
          "Rugarama",
        ],
      },

      {
        province: "Rubirizi",
        towns: ["Bunyaruguru", "Katerera"],
        territories: [
          "Katunguru",
          "Kichwamba",
          "Magambo",
          "Rubirizi town council",
          "Ryeru",
        ],
      },

      {
        province: "Rukungiri",
        towns: ["Rubabo", "Rujumbura", "Rukungiri MC"],
        territories: [
          "Buyanja",
          "Kebisoni",
          "Nyakishenyi",
          "Bugangari",
          "Bwambara",
          "Nyakagyeme",
          "Ruhinda",
        ],
      },

      {
        province: "110. Sheema",
        towns: ["Sheema"],
        territories: [
          "Bugongi",
          "Kabwohe town council",
          "Kagango",
          "Kasaana",
          "Kashozi",
          "Rugarama",
          "Sheema town",
        ],
      },
    ],
  },
];
